import React, { Component } from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import SEO from '~components/seo';
import PageWrapper from '~components/page-wrapper/page-wrapper.component';
import Hero from '~components/hero/hero.component';
import FeaturedCardList from '~components/featured-card-list/featured-card-list.component';
import CtaFeatured from '~components/cta-featured/cta-featured.component';
import CardList from '~components/card-list/card-list.component';

class LandingPageTemplate extends Component {
  renderHero = () => {
    const { hero } = this.props.data.contentfulLandingPage;

    if (!hero) {
      return null;
    }

    return <Hero data={hero} isExternal={false} />;
  }

  renderCtaFeatured = () => {
    const page = get(this.props, 'data.contentfulLandingPage');
    if (!page || (!page.length && !Object.keys(page).length)) {
      return null;
    }

    const {
      ctaFeatured,
    } = page;

    if (!ctaFeatured) {
      return null;
    }

    return (
      <CtaFeatured
        {...ctaFeatured}
      />
    );
  }

  render() {
    const page = get(this.props, 'data.contentfulLandingPage');

    const { renderCtaFeatured } = this;

    return (
      <PageWrapper
        hero={this.renderHero()}
        references={page.references}
      >
        <SEO title={page.title} />
        <FeaturedCardList cards={page.featuredCards} />
        {renderCtaFeatured()}
        <CardList cards={page.contentCards} />
      </PageWrapper>
    )
  }
}

export default LandingPageTemplate;

// The HERO Bynder Image code.
// bynderBackgroundImage {
//   description
//   name
//   src
//   original
// }

export const query = graphql`
  query landingPage($contentful_id: String) {
    contentfulLandingPage(contentful_id: {eq: $contentful_id}) {
      contentful_id
      slug
      title
      hero {
        bannerCopy
        image {
          file {
            url
          }
          description
          title
        }
        bynderBackgroundImage {
          description
          name
          src
          original
        }
        slides {
          ... on Node {
            ... on ContentfulCtaBlock {
              title
              childContentfulCtaBlockBodyTextNode {
                body
              }
              ctaText
              ctaUrl
            }
            ... on ContentfulResourceCard {
              title
              body {
                body
              }
              linkText
              resourceFile {
                file {
                  url
                }
              }
              resourceUrl
              iFrame
            }
          }
        }
      }
      featuredCards {
        title
        body {
          body
        }
        linkText
        link
        image {
          fluid(maxWidth: 550, resizingBehavior: SCALE) {
            ...GatsbyContentfulFluid_tracedSVG
          }
          description
        }
        bynderImage {
          description
          name
          src
        }
      }
      ctaFeatured {
        title
        body {
          body
        }
        ctaText
        ctaUrl
      }
      contentCards {
        ... on Node {
          ... on ContentfulContentCard {
            title
            body {
              body
            }
            linkText
            link
            image {
              fluid(maxWidth: 550, resizingBehavior: SCALE) {
                ...GatsbyContentfulFluid_tracedSVG
              }
              description
            }
            bynderImage {
              description
              name
              src
            }
          }
          ... on ContentfulResourceCard {
            title
            body {
              body
            }
            linkText
            resourceFile {
              file {
                url
              }
            }
            resourceUrl
            iFrame
            image {
              fluid(maxWidth: 550, resizingBehavior: SCALE) {
                ...GatsbyContentfulFluid_tracedSVG
              }
              description
            }
            bynderImage {
              description
              name
              src
            }
          }
        }
      }
      references {
        references
      }
    }
  }
`
